@mixin border ($size, $darkness) {
    border: $size + px solid rgba(0,0,0,$darkness);
}

@mixin borderBottom ($size, $darkness) {
    border-bottom: $size + px solid rgba(0,0,0,$darkness);
}

@mixin borderTop ($size, $darkness) {
    border-top: $size + px solid rgba(0,0,0,$darkness);
}

@mixin borderLeft ($size, $darkness) {
    border-left: $size + px solid rgba(0,0,0,$darkness);
}

@mixin borderRight ($size, $darkness) {
    border-right: $size + px solid rgba(0,0,0,$darkness);
}

@mixin shadow ($x, $y, $darkness) {
    box-shadow: 0 $x + px $y + px rgba(0,0,0,$darkness);
}

@mixin gradient($start, $end) {
    background-color: $start;
    background: $start; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient($start, $end); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient($start, $end); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient($start, $end); /* For Firefox 3.6 to 15 */
    background: linear-gradient($start, $end); /* Standard syntax */
}
@mixin gradient-horizontal($start, $end) {
  background: $start; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, $start , $end); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, $start, $end); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, $start, $end); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, $start , $end); /* Standard syntax */
}

@mixin clearfix() {
    &:after {
        content: "";
        clear: both;
        display: table;
    }
}

// Animations

@mixin fadeIn($duration, $delay) {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: $delay;
  animation-duration: $duration;
}
@mixin fadeOut($duration, $delay) {
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: $delay;
  animation-duration: $duration;
}
@mixin bounce($duration, $delay) {
  animation-name: bounce;
  //animation-fill-mode: forwards;
  //animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-iteration-count: infinite;
  animation-delay: $delay;
  animation-duration: $duration;
}

@mixin form {
    position: relative;
    background-color: $secondary;
    border-radius: 25px 25px 0 0;
    box-shadow: 13.523px 3.623px 13px 0px rgba(0, 0, 0, 0.24);
    padding: 30px 30px 0 30px;
    text-align: center;
    float: right;
    overflow: hidden;
    .form {
      padding: 15px 15px 0 15px;
      position: relative;
      z-index: 9;
    }
    .tit {
      font-weight: 600;
      color: $white;
      font-size: 35px;
      margin-bottom: 40px;
      position: relative;
      z-index: 9;
    }
    .note {
      color: $white;
      font-size: 14px;
      padding-top: 30px;
      position: relative;
      z-index: 9;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      background-image: url("../images/bg-purse-form.png");
      width: 336px;
      height: 345px;
      bottom: 15px;
      right: -45px;
    }

}
.jq-checkbox {
  border: 1px solid $secondary;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &.checked:after{
    content: '\f00c';
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: $secondary;
  }
}
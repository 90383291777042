$font-size: 16;
$duration: 300ms;
$font-stack: 'Ubuntu', sans-serif;

$shadow: 0px 5px 4px 3px rgba(41,41,41,0.25);

// Colors
$main: #2a2a2a;
$secondary: #7fc719;
$third: #435059;
$black: #000000;
$white: #ffffff;
$section-bg: #2a2a2a;
$border: #dadada;
$h1: #252525;
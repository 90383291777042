* {
  outline: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  line-height: 1.2;
}

html {
  font-family: $font-stack;
  font-size: $font-size + px;
  color: $main;
  padding: 0;
  margin: 0;
  font-weight: 400;
  transition: transform $duration ease;
  height: 100%;
  background-color: #f6f6f6;
}

body {
  font-family: inherit;
  padding: 0;
  margin: 0;
  color: inherit;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.img-wrap {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

::-moz-selection {
  color: #fff;
  background: $main;
}

::selection {
  color: #fff;
  background: $main;
}

a {
  text-decoration: none;
  outline: 0;
  color: $secondary;
  transition: all $duration linear;
  border-bottom: 1px solid transparent;
  &:focus {
    text-decoration: none;
    outline: 0;
    color: $secondary;
  }
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid $secondary;
    outline: 0;
    color: $secondary;
  }
  &:active {
    text-decoration: none;
    outline: 0;
  }
  &.link {

  }
}

.full-width {
  width: 100% !important;
}

table {
  overflow-y: hidden;
  width: 100%;
  text-align: left;
  border-spacing: 0;
  thead {
    th {

    }
  }
  tbody {
    th {

    }
    td {

    }
    tr {
      &:first-of-type {
        td, th {

        }
      }
      &:last-of-type {
        td, th {
          //
        }
      }
      &:nth-child(odd) {

      }
    }
  }
}

.hidden {
  display: none;
}

hr, .hr {
  display: block;
  height: 1px;
  padding: 0;
  margin: 20px 0;
  border: 0;
  border-top: 1px solid $border;
}

svg {
  width: 100%;
}

.fix {
  position: fixed;
  z-index: 99;
}

//to top
#scrollup {
  position: fixed;
  color: $third;
  right: 2%;
  bottom: 20%;
  display: none;
  cursor: pointer;
  font-size: 50px;
  z-index: 99;
}

//off-canvas menu
.uk-offcanvas {
  &-bar {
    padding: 15px;
  }

  &-title {
    font-size: 22px;
    color: #fff;
  }

  &-contact {

  }
  &-list {
    li {
      margin-bottom: 10px;
      a {
        font-size: 14px;
      }
    }
    .sub-menu {
      padding-left: 15px;
      padding-top: 10px;
    }
  }
  .but-close {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 1;
    color: #ffffff;
  }

}

//modal dialog window
.md {
  max-width: 545px;
  width: 100%;
  text-align: center;
  padding: 45px 30px;
  background-color: $secondary;
  .title-md {
    font-size: 35px;
    line-height: 1.25em;
    margin-bottom: 25px;
    color: $white;
    font-weight: 600;
  }
  p {
    color: $white;
    margin-bottom: 15px;
    font-size: 20px;
    margin-top: 0;
  }
  .note{
    font-size: 14px;
    line-height: 1.25em;
    color: $white;
    font-weight: 600;
    margin-top: 35px;
  }
  input, textarea{
    width: 270px;
    display: inline-block;
  }
}

//fancybox
.fancybox-bg {
  //background: rgba(192, 204, 211, 0.9);
  &:after {
    content: '';
    font-family: $font-stack;
    display: block;
    font-size: 240px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $secondary;
  }
  &:before {
    content: '';
    font-family: $font-stack;
    display: block;
    font-size: 40px;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $secondary;
    text-align: center;
  }
}

//carousel
.owl {
  &-prev, &-next {
    font-size: 90px;
    color: $secondary;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    opacity: 0.7;
    transition: all 600ms ease;
    &:hover {
      opacity: 1;
      transition: all 600ms ease;
    }
  }

  &-prev {
    left: -8%;
  }

  &-next {
    right: -8%;
  }

  &-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    .owl-dot {
      width: 30px;
      height: 4px;
      margin-right: 5px;
      background-color: $secondary;
      display: inline-block;
      transition: all 600ms ease;
      &.active {
        height: 9px;
        transition: all 600ms ease;
      }
    }
  }

}

//bread crumb
.breadcrumb {
  background: none;
  border: none;
  padding: 30px 15px;
  margin-bottom: 15px;
  ul {
    li {
      font-size: 18px;
      line-height: 1.2em;
      color: $main;
      display: inline-block;
      font-weight: 900;
      a {
        color: $secondary;
        font-weight: normal;
      }
      & + li {
        &:before {
          content: '/';
          display: inline-block;
          color: $main;
          margin: 0 15px;
          font-weight: bold;
        }
      }
    }
  }
}

//pagination
.pagination {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 30px 0;

  ul {
    display: inline-block;
    vertical-align: middle;
    margin: 0 25px;
    li {
      display: inline-block;
      padding: 0 5px;
      a {
        display: inline-block;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        text-align: center;
        line-height: 48px;
        font-size: 20px;
        border: 1px solid $secondary;
        color: $secondary;
        &:hover {
          background-color: $secondary;
          border: 1px solid transparent;
          text-decoration: none;
          color: #ffffff;
        }
      }
      &.active {
        a {
          background-color: $secondary;
          border: 1px solid transparent;
          text-decoration: none;
          color: #ffffff;
        }
      }
    }
  }
}

//title section
.title {
  position: relative;
  text-align: left;
  padding: 35px 30px;
  margin-left: -30px;
  margin-bottom: 60px;
  font-family: $font-stack;
  &:after, &:before {
    content: '';
    display: block;
    border-right: 5px solid $secondary;
    height: 25px;
    position: absolute;
    left: 0;
    width: 630px;
  }
  &:after {
    top: 0;
  }
  &:before {
    bottom: 0;
  }
  .icn, span {
    display: inline-block;
    vertical-align: top;
  }
  span {
    font-size: 80px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    line-height: 0.6em;
    padding-left: 15px;
    small {
      font-size: 30px;
      line-height: 50px;
      display: block;
      text-transform: uppercase;
      text-align: right;
      font-weight: 400;
      padding-top: 15px;
      padding-right: 30px;
    }

  }
  .wrap {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 630px;
      height: 100%;
      border-top: 5px solid $secondary;
      border-left: 5px solid $secondary;
      border-bottom: 5px solid $secondary;
    }
  }
  &-white {
    span {
      color: $white;
    }
  }
  &-simple {
    span {
      font-size: 60px;
      line-height: 1.2em;
    }
  }
}
h1{
  font-family: $font-stack;
}
.content {
  p, h2, h3, h4, li {
    line-height: 1.4em;
    margin-top: 0;
  }
  p, h2, h3 {
    margin-bottom: 35px;
  }
  h2, h3, h4 {
    font-weight: 600;
  }
  h2, h3 {
    color: $secondary;
  }
  p {
    font-size: 20px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 25px;
  }
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.25em;
    margin-bottom: 0;
  }
  ul {
    padding-left: 50px;
    li {
      font-size: 20px;
      margin-bottom: 20px;
      position: relative;
      &:after {
        content: '\f105';
        font-family: FontAwesome;
        position: absolute;
        top: 0;
        left: -15px;
        color: $secondary;
        font-weight: 600;
      }
    }
  }

  a {
    color: $secondary;
    border-bottom: 1px solid $secondary;
    &:hover {
      border-color: transparent;
    }
  }
  .ya-share2 {
    &__list {
      padding: 0;
    }
    &__item {
      &:after {
        display: none;
      }
    }
  }
}

.alignleft {
  float: left;
  padding: 0 20px 35px 0;
}

.alignright {
  float: right;
  padding: 0 0 35px 20px;
}

.aligncenter {
  display: block;
  margin: 0 auto 35px 0;
}
.btn-wrap {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  width: 250px;
  max-width: 100%;
  position: relative;
  height: auto;
  &__double{
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    .btn{
      display: inline-block;
      width: 260px;
      &:not(:last-child){
        margin-right: 30px;
      }
    }
  }
}



.btn, input[type='submit'], button[type='submit'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 17px;
  text-align: center;
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: #fff;
  border: 5px solid transparent;
  transition: all $duration ease;
  overflow: hidden;
  height: 50px;
  line-height: 1.2em;
  cursor: pointer;
  font-family: $font-stack;
  -webkit-touch-callout: none;
  user-select: none;
  border-radius: 26.5px;
  text-transform: uppercase;
  box-shadow: 5.47px 12.887px 13px 0px rgba(0, 0, 0, 0.24);
  &:focus {
    outline: none;
    color: #ffffff;
  }
  &:hover {
    outline: none;
  }
  &:active {

  }
  &.btn-white{
    background-color: $white;
    color: $secondary;
    &:hover {

      background-color: $secondary;
      color: $white;
      border-color: $white;
    }
    &:focus {
      border-color: transparent;
      background-color: $secondary;
    }
  }
  &.btn-green {
    border-color: transparent;
    background-color: $secondary;
    &:focus {
      border-color: $secondary;
      background-color: #ffffff;
      color: $secondary;
    }
    &:hover {
      border-color: $secondary;
      background-color: #ffffff;
      color: $secondary;
    }
    &:active {

    }
  }
  &.btn-big{
    height: 80px;
    border-radius: 40px;
    font-size: 20px;
    &:hover{
      border-width: 5px;
    }
  }
  &.btn-medium{
    height: 50px;
    border-radius: 30px;
    border-width: 2px;
    &:hover{
      border-width: 2px;
    }
  }
  &.btn-small{
    border-width: 2px;
    height: 40px;
    border-radius: 20px;
    &:hover{
      border-width: 2px;
    }
  }
  i.fa{
    margin-left: 10px;
  }
}



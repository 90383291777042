//section
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px 0 12px;
    height: 100px;
    position: relative;
  }
  .head-item {
    width: 20%;
    padding: 0 15px;
  }
  .tagline {
    position: relative;
    &:after, &:before {
      content: '';
      display: block;
      border-right: 1px solid $secondary;
      height: 10px;
      position: absolute;
      right: 25px;
    }
    &:after {
      top: 0;
    }
    &:before {
      bottom: 0;
    }
    .wrap {
      position: relative;
      padding: 10px 0;
      color: $white;
      font-size: 17px;
      line-height: 25px;
      margin-left: 20px;
      &:after {
        content: '';
        border-top: 1px solid $secondary;
        border-left: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
        position: absolute;
        top: 0;
        left: -10px;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-contact {
    li {
      margin-bottom: 10px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      &.text {
        width: 84%;
        color: $white;
        padding-left: 10px;
        font-size: 14px;
        line-height: 18px;
        a {
          color: $white;
          border-bottom: 1px solid $white;
          &:hover {
            text-decoration: none;
            border-bottom: 1px solid transparent;
          }
        }
      }
    }

  }
  &-phone {
    .phone {
      font-size: 22px;
      line-height: 30px;
      color: $white;
      font-weight: 600;
      text-align: right;
      padding-right: 15px;
      margin-bottom: 10px;
    }
    .btn-wrap {
      .btn {
        font-size: 14px;
        height: 40px;
        text-transform: none;
      }
    }
  }
  .logo {
    text-align: center;
    a {
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__inside {
    position: relative;
    background-image: url("../images/bg-header.jpg");
    background-size: cover;
  }
}

.first {
  background-image: url("../images/first.png");
  height: 1053px;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-top: 100px;
  overflow: hidden;
  .container, .row, .wrapper {
    height: 100%;
  }
  .romb-filter {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background-image: url("../images/filtr-img-first.png");
      width: 753px;
      height: 510px;
      right: -15%;
      bottom: 23%;
      z-index: 7;
    }
    &:after {
      content: '';
      display: block;
      background-image: url("../images/romb-big-first.png");
      width: 931px;
      height: 836px;
      position: absolute;
      bottom: 4px;
      right: -25%;
      z-index: 5;
    }
  }
  .wrapper {
    padding: 50px 0;
    position: relative;

  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
  }

  .btn-wrap {
    width: 400px;
  }
  .h1-block {
    margin-bottom: 35px;
    position: relative;
    z-index: 9;
    .wrap {
      position: relative;
      &:after, &:before {
        content: '';
        display: block;
        border-right: 5px solid $secondary;
        height: 25px;
        position: absolute;
        left: 0;
        width: 600px;
      }
      &:after {
        top: 0;
      }
      &:before {
        bottom: 0;
      }
    }

  }
  h1 {
    color: $white;
    position: relative;
    font-family: $font-stack;
    display: inline-block;
    padding: 35px 30px;
    margin-left: -30px;
    margin-bottom: 0;
    font-size: 60px;
    width: 860px;
    font-weight: 600;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 630px;
      height: 100%;
      border-top: 5px solid $secondary;
      border-left: 5px solid $secondary;
      border-bottom: 5px solid $secondary;

    }
    span {
      color: $secondary;
      text-transform: uppercase;
    }
  }
  .subtitle {
    font-size: 40px;
    line-height: 60px;
    color: $white;
    font-weight: 600;
    span {
      font-size: 30px;
      line-height: 50px;
      font-weight: normal;
      display: block;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    width: 5/12 * 100%;
    .item {
      width: 50%;
      margin-bottom: 30px;
      span {
        display: inline-block;
        vertical-align: middle;
        &.text {
          width: 75%;
          color: $white;
          font-size: 17px;
          line-height: 20px;
          padding-left: 10px;
        }
      }
    }
  }
  &-bottom {
    display: block;
    width: 100%;
    background-image: url("../images/gray-first.png");
    height: 320px;
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 6;
    .wrapper {
      padding: 0;
    }
    &__about {
      background: $section-bg;
      position: relative;
      left: 0;
      bottom: 0;
      height: 270px;
      .first-edge-list {
        padding: 60px 0 0;
      }
    }
  }
  &-edge-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 115px 0 0;
    .item {
      width: 33.33%;
      padding: 0 15px;
      &:nth-child(2) {
        padding-top: 45px;
      }
    }
    .text, .icn {
      display: inline-block;
      vertical-align: top;
    }
    .text {
      width: 71%;
      padding: 0 0 0 10px;
      span {
        font-size: 45px;
        line-height: 1em;
        color: $secondary;
        margin-bottom: 15px;
        display: block;
        font-weight: 600;
      }
      color: $white;
      font-size: 17px;
    }
  }

}

.reception {
  padding: 85px 0;
  .title {
    span {
      small {
        text-align: right;
        padding-right: 65px;
      }
    }
  }
  &-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .item {
      width: 33.33%;
      padding: 0 18px;

      .wrap {
        background-color: #fff;
        box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.5);
        .img-wrap {
          position: relative;
          &:after {
            content: '';

          }
        }
        .text {
          padding: 30px 20px;
          position: relative;
          &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 30px;
            margin-left: -30px;
          }
          .name {
            font-size: 22px;
            font-weight: 600;
            color: $secondary;
            margin-bottom: 15px;
          }
          ul {
            li {
              margin-bottom: 15px;
              &:before {
                content: '\f105';
                font-family: FontAwesome;
                color: $secondary;
                margin-right: 15px;
              }
              a {
                font-size: 17px;
                color: $main;
                font-weight: 500;
                &:hover {
                  color: $secondary;
                }
              }
            }
          }
          p {
            font-size: 17px;
            line-height: 35px;
            color: $main;
            font-weight: 500;
            margin: 0;
            padding: 0;
            a {
              color: $secondary;
              text-decoration: underline;
              font-weight: 500;
              &:hover {
                color: $secondary;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}

.process {
  background-image: url("../images/bg-process.jpg");
  background-size: cover;
  height: 849px;
  padding: 100px 0;
  &-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 45px;
    .item {
      width: 20%;
      padding: 0 15px;
      text-align: center;
      .img-wrap {
        margin-bottom: 15px;
        position: relative;
        img {
          border-radius: 50%;
        }
        .number {
          position: absolute;
          bottom: 15px;
          left: 0px;
          background-image: url("../images/romb-small.png");
          width: 57px;
          height: 57px;
          text-align: center;
          font-size: 25px;
          line-height: 57px;
          color: $white;
        }
      }
    }
    .name {
      font-size: 17px;
      line-height: 1.25em;
      color: $white;
      font-weight: 600;

    }
  }
  .btn-wrap {
    width: 350px;
    margin: 0 auto;
    display: block;
  }
}

.how {
  background-image: url("../images/bg-how.jpg");
  height: 689px;
  background-size: cover;
  padding: 93px 0 0 0;
  .container, .row {
    height: 100%;
  }
  &-list {
    .item {
      margin-bottom: 30px;
    }
    .icn-wrap, .text {
      display: inline-block;
      vertical-align: middle;
    }
    .icn-wrap {

    }
    .text {
      width: 80%;
      padding-left: 30px;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      span {
        font-size: 20px;
        color: $white;
        font-weight: 600;
        margin-bottom: 15px;
      }
      a {
        font-size: 20px;
        font-weight: 600;
        border-bottom: 2px solid $secondary;
        &:hover {
          text-decoration: none;
          border-bottom: 2px solid transparent;
        }
      }
    }
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    .how-1, .how-2 {
      padding: 0 15px;
      height: 100%;
    }
    .how-1 {
      width: 7/12 * 100%;
    }
    .how-2 {
      width: 5/12 * 100%;
      .wrap {
        width: 435px;
        height: 100%;
        @include form;
      }
    }
  }
}

.why {
  background: url("../images/img-filtr-big.png") no-repeat;
  background-size: 60%;
  background-position-x: 100%;
  background-position-y: 110%;
  padding: 110px 0 80px;
  &-wrap {
    width: 50%;
    padding: 0 15px;
    .about-link {
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      margin-left: 160px;
    }
  }
  &-list {
    .item {
      margin-bottom: 30px;
      a {
        font-weight: 600;
        border-bottom: 1px solid $secondary;
        &:hover {
          border-color: transparent;
        }
      }
      .tit {
        font-size: 25px;
        margin-bottom: 15px;
        font-weight: 600;
      }
    }
    .icn-wrap, .text {
      display: inline-block;
      vertical-align: top;
    }
    .text {
      width: 70%;
      padding-left: 30px;
      font-size: 17px;
    }
  }
}

.reviews {
  padding: 120px 0 80px;
  background-color: $section-bg;
  &-list {
    columns: 2;
    .item {
      padding: 0 15px;
      margin-bottom: 30px;
      .wrap {
        padding: 70px 40px 40px;
        border: 5px solid $secondary;
        background-image: url("../images/icn-query.png");
        background-position: 5% 5%;
        background-repeat: no-repeat;
        .name {
          color: $white;
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 30px;
        }
        p {
          color: $white;
          font-size: 17px;
          line-height: 1.25em;
          margin: 0 0 20px;
        }
        time {
          font-size: 17px;
          float: right;
          color: $secondary;
        }
      }

    }

  }
}

.consult {
  padding: 110px 0 80px;
  .title{
    margin-bottom: 35px;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .consult-1, .consult-2 {
      padding: 0 15px;
    }
    .consult-1 {
      width: 7/12 * 100%;
      .text {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 55px;
      }
      .btn-wrap{
        width: 400px;
      }
    }
    .consult-2 {
      width: 4/12*100%;
      .img-wrap {
        margin-bottom: 35px;
      }
      .consult-name {
        position: relative;
        padding: 30px 20px;
        &:after, &:before {
          content: '';
          display: block;
          border-right: 5px solid $secondary;
          height: 25px;
          position: absolute;
          left: 0;
          width: 270px;
        }
        &:after {
          top: 0;
        }
        &:before {
          bottom: 0;
        }
        .wrap {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 270px;
            height: 100%;
            border-top: 5px solid $secondary;
            border-left: 5px solid $secondary;
            border-bottom: 5px solid $secondary;
          }
          span {
            font-size: 30px;
            text-transform: uppercase;
            small {
              font-size: 20px;
              text-transform: none;
              display: block;
            }
          }
        }
      }
    }
  }
}

.action {
  background-color: $secondary;
  padding: 40px 0 20px;
  .wrapper {
    display: flex;
    flex-direction: row;

  }
  &-name {
    width: 5 /12 * 100%;
    color: $white;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    span {
      font-size: 17px;
      font-weight: normal;
    }

  }
  &-form {
    width: 7 / 12 *100%;
    .input-wrap {
      width: 618px;
      height: 80px;
      border-radius: 5px 0 0 5px;
      float: right;
      position: relative;
      &:after {
        content: '';
        border: 5px solid $white;
        border-right: none;
        width: 80%;
        height: 80px;
        position: absolute;
        top: 0;
        left: 0;
      }
      input:not([type=submit]) {
        border: none;
        height: 80px;
        font-size: 18px;
        margin: 0;
        width: 620px;
        padding-right: 280px;
        position: relative;
        z-index: 9;
      }
      .btn-wrap {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        z-index: 10;
      }
      input[type=submit] {
        width: 275px;
        height: 80px;
        border-radius: 40px;
        font-size: 20px;
      }
    }
  }
}

.list-gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  .item {
    width: 25%;
    padding: 0 15px;
    a {
      display: inline-block;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../images/plus-zoom.png) no-repeat center center, rgba(37, 37, 37, 0.65);
        transition: all 0.3s linear;
        opacity: 0;
      }
      &:hover {
        &:after {
          opacity: 1;
          transition: all 0.3s linear;
        }
      }
    }
  }
}

.footer {
  .top {
    background-color: $section-bg;
    padding: 45px 0;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .item {
      padding: 0 15px;
    }
    .logo {
      text-align:left;
      .note {
        font-weight: 400;
        font-size: 20px;
        color: #e8e8e8;

        padding-top: 15px;
      }
    }
  }

  &-menu {
    width: 25%;
    padding: 0 !important;
    &-2 {
      width: 38%;
    }
    ul {
      li {
        margin-bottom: 20px;
        a {
          font-size: 17px;
          text-transform: uppercase;
          color: $white;
          font-weight: 600;
          padding-left: 30px;
          position: relative;
          border-bottom: 1px solid transparent;
          &:after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid $secondary;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            left: 0;
          }
          &:hover {
            color: $secondary;
            border-bottom: 1px solid transparent;
            &:after {
              background-color: $secondary;
            }
          }
        }
      }
      .sub-menu {
        padding-left: 20px;
        padding-top: 15px;
        li {
          a {
            padding-left: 15px;
            text-transform: none;
            &:after {
              content: '\f105';
              font-family: FontAwesome;
              display: block;
              width: 20px;
              height: 20px;
              border: 2px solid transparent;
              color: $secondary;
              transform: rotate(0deg);
              position: absolute;
              top: 0;
              left: 0;
            }
            &:hover {
              &:after {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  &-social {
    width: 33.33%;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 55px;
      li {
        a {
          font-size: 22px;
          position: relative;
          color: $white;
          line-height: 50px;
          display: inline-block;
          width: 50px;
          height: 50px;
          text-align: center;
          border-bottom: 1px solid transparent;
          &:hover {
            color: $secondary;
            border-bottom: 1px solid transparent;
            &:after {
              background-color: $white;
            }
          }
          i.fa {
            position: relative;
            z-index: 99;
          }
          &:after {
            content: '';
            width: 50px;
            height: 50px;
            position: absolute;
            transform: rotate(45deg);
            top: 0;
            left: 0;
            border-radius: 5px;
            background-color: $secondary;
          }
        }
      }
    }
    .note {
      font-weight: 600;
      font-size: 20px;
      color: $white;
      margin-bottom: 35px;
    }
    .btn-wrap {
      width: 100%;
    }
  }
  .copy {
    text-align: center;
    padding: 20px 0;
    color: #898989;
    font-size: 14px;
  }
  .btn {
    border-width: 2px;
  }
}

//page style
main {
  padding-bottom: 135px;
  background: url("../images/triangl-big-left.png") no-repeat left 25%, url("../images/triangl-big-right.png") no-repeat right 65%, #f6f6f6;
  .wrapper {
    background: url("../images/triangl-small.png") no-repeat top right;
  }
  .top-main {
    text-align: center;
  }
  .h1-block {
    position: relative;
    margin: 25px auto 70px;
    display: inline-block;
    width: 75%;
    h1 {
      font-size: 60px;
      font-weight: 600;
      color: $h1;
      line-height: 1.2em;
      background-color: #f6f6f6;
      position: relative;
      z-index: 9;
      padding: 0;
      margin: 0;
      width: auto;
      display: inline-block;
      &:before, &:after {
        content: '';
        position: absolute;
        border-left: 3px solid $secondary;
        border-right: 3px solid $secondary;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        min-width: 180px;
        height: 25px;
      }
      &:before {
        border-top: 3px solid $secondary;
        top: -25px;
      }
      &:after {
        bottom: -25px;
        border-bottom: 3px solid $secondary;
      }

    }
  }
  &.page-contact {
    padding-bottom: 0;
  }
  &.category-katalizator{
    padding-bottom: 60px;
  }
}

.blog {
  padding-bottom: 0;
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 50%;
      padding: 0 15px;
      margin-bottom: 30px;
      a {
        border-color: transparent;
        display: inline-block;
        box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.5);
        .img-wrap {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 10px;
            border-bottom: 10px solid $secondary;
            bottom: 0;
            left: 0;
          }
          time {
            position: absolute;
            bottom: 15px;
            right: 10px;
            border-radius: 50%;
            width: 75px;
            height: 75px;
            color: $white;
            line-height: 75px;
            font-size: 16px;
            display: inline-block;
            text-align: center;
            background-color: $secondary;
          }
        }
        .wrap {
          padding: 30px;
          background-color: #fff;
        }
        .name {
          font-size: 25px;
          line-height: 1.25em;
          color: $main;
          display: inline-block;
          margin-bottom: 30px;
          font-weight: 600;
        }
        p {
          font-size: 20px;
          line-height: 1.25em;
          color: $main;
        }
        &:hover {
          border-color: transparent;
          box-shadow: none;
          .name {
            color: $secondary;
          }
        }
      }
    }
  }
}

.article__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 5px solid $secondary;
  .left {
    font-size: 30px;
    font-weight: 600;
    span {
      font-weight: normal;
      font-size: 20px;
      display: block;
    }
  }
}

.page {
  .slogan {
    width: 8/12 * 100%;
    margin: 0 auto 55px;
    font-size: 20px;
    line-height: 30px;
  }

}

.vacancy {
  .we-offer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 65px;
    .img, .text {
      width: 50%;
      padding: 0 15px;
    }
    .text {
      ul {
        li {
          &:after {
            content: '\f00c';
            left: -25px;
          }
        }
      }
    }
  }
  &-list {
    .item {
      margin-bottom: 105px;
      .info-list {
        margin-bottom: 35px;
        li {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        i.fa {
          margin-right: 10px;
          width: 15px;
        }
      }
      .name {
        font-size: 25px;
        margin-bottom: 35px;
        font-weight: 600;
      }
      .text {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 35px;
      }
      .salary {
        font-size: 25px;
        margin-bottom: 35px;
        font-weight: 600;
        span {
          border-bottom: 5px solid $secondary;
        }
      }
      .btn-wrap {
        width: 350px;
      }
      .requirements-list {
        padding-left: 50px;
        li {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 10px;
          position: relative;
          &:after {
            content: '\f105';
            display: block;
            font-family: FontAwesome;
            position: absolute;
            top: 0;
            left: -10px;
            color: $secondary;
          }
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.contact-map {
  position: relative;
  height: 660px;
  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 660px;
  }
  .contact-block {
    position: relative;
    z-index: 99;
    float: left;
    width: 100%;
    padding-top: 80px;
    .wrap {
      width: 530px;
      height: 530px;
      border-radius: 50%;
      background-color: $secondary;
      box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.5);
      padding: 65px 100px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }
    address {
      color: $white;
      font-size: 22px;
      margin-bottom: 0px;
    }
    .phone-block {
      font-size: 35px;
      color: $white;
      padding: 0;
      display: flex;
      flex-direction: column;
      .phone {
        display: inline-block;
      }
    }
    a:not(.btn) {
      color: $white;
      &:hover {
        border-color: $white;
      }
      &[href*=mailto] {
        font-size: 22px;
        border-bottom: 1px solid $white;
        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

.simple {
  padding-top: 80px;
}

.about {
  padding-bottom: 0;
  &-text {
    width: 8/12 * 100%;
    margin: 0 auto 80px;
    ul {
      padding-left: 0;
      li {
        &:after {
          display: none;
        }
        a {
          color: $main;
          text-transform: uppercase;
          font-weight: 600;
          border-color: transparent;
          &:hover {
            color: $main;
            border-color: $main;
          }
        }
        ul {
          padding-top: 20px;
          padding-left: 20px;
          li {

            &:after {
              display: block;
            }
            a {
              text-transform: none;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

}

.partners {
  &-text {
    width: 8/12 * 100%;
    margin: 0 auto 0px;
  }
}

.part-form {
  background-color: $section-bg;
  padding: 100px 0 70px;
  .note-top {
    text-align: center;
    font-size: 20px;
    color: $white;
    margin-bottom: 30px;
    width: 100%;
  }
  .wrapper {
    width: 9/12 *100%;
    margin: 0 auto;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    .note-form {
      text-align: center;
      color: $white;
      padding: 30px 0;
      width: 100%;
    }
    .input-group {
      padding: 0 10px;
      width: 33.33%;
      input:not([type=submit]) {
        margin-bottom: 15px;
        height: 50px;
        font-size: 16px;
        width: 270px;
        &:focus {
          border-color: $secondary;
        }
      }
      textarea {
        height: 180px;
        font-size: 16px;
        width: 270px;
        &:focus {
          border-color: $secondary;
        }
      }
    }
    .btn-wrap {
      width: 400px;
      padding: 0 15px;
      input[type=submit] {
        height: 80px;
        border-radius: 40px;
        font-size: 20px;
      }
    }
  }
}

.serteficate {
  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      width: 33.33%;
      padding: 0 15px;
      margin-bottom: 50px;
      a {
        border-color: transparent;
        p {
          padding-top: 35px;
          font-size: 20px;
          margin: 0;
          color: $main;
        }
        &:hover {
          border-color: transparent;
          p{
            color: $secondary;
          }
        }
      }
    }

  }
}

.deference-bg {
  background: none;
  //background: url("../images/triangl-big-left.png") no-repeat left 25%, linear-gradient(#f6f6f6 20%, #fff 80%);
  .triangle-deference {
    .top {
      background-color: #f6f6f6;
      padding: 0 0 80px 0;
      position: relative;
      .container{
        position: relative;
        z-index: 99;
      }
      &:after{
        content: '';
        background: url("../images/triangl-big-left.png") no-repeat left center;

        background-size: 100%;
        width: 19.5vw;
        height: 931px;
        position: absolute;
        display: block;
        bottom: 0;
        left:0;
        transform: translateY(50%);
      }
    }
    .middle {
      padding: 90px 0 0px;
      background: url("../images/img-filtr-big-2.png") no-repeat right bottom;
    }
  }
}

.sell {
  padding: 100px 0 0px;
  .container{
    position: relative;
    z-index: 99;
  }
  .text {
    width: 8/12 *100%;
    padding: 0 15px;
  }
}

.category-katalizator-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item {
    width: 33.33%;
    padding: 0 15px;
    a {
      display: inline-block;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.5);
      border-color: transparent;
      border: none;
      .img-wrap {
        position: relative;
        display: block;
        img{
          width: 100%;
        }
        &:after {
          content: '';

        }
      }
      .text {
        padding: 30px 30px;
        position: relative;
        background-color: #fff;
        display: block;
        text-align: center;
        min-height: 125px;
        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #fff;
          border-width: 30px;
          margin-left: -30px;
        }
        .name {
          font-size: 22px;
          font-weight: 600;
          color: $main;
          margin-bottom: 15px;
        }
      }
      &:hover {
        border-color: transparent;
        .text {
          background-color: $secondary;
          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: $secondary;
          }
          .name {
            color: $white;
          }
        }
      }
    }
  }
}

//other style

.with-sidebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .main-block {
    width: 8/12 *100%;
    padding: 0 15px;
  }
  .sidebar {
    width: 4/12 * 100%;
    padding: 0 15px;
    aside {
      box-shadow: 13.523px 3.623px 13px 0px rgba(0, 0, 0, 0.24);
      margin-bottom: 50px;
      width: 100%;
      .sidebar-title {
        font-weight: 600;
        color: $white;
        font-size: 28px;
        margin-bottom: 30px;
        position: relative;
        z-index: 9;
        text-align: center;
      }

    }
    aside.menu-sidebar {
      background-color: $secondary;
      position: relative;
      z-index: 99;
      padding: 20px 30px;
      ul {
        li {
          margin-bottom: 20px;
          a {
            font-size: 17px;
            text-transform: uppercase;
            color: $white;
            font-weight: 600;
            padding-left: 30px;
            position: relative;
            border-bottom: 1px solid transparent;
            &:after {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              border: 2px solid $white;
              transform: rotate(45deg);
              position: absolute;
              top: 0;
              left: 0;
            }
            &:hover {
              color: $white;
              border-bottom: 1px solid transparent;
              &:after {
                background-color: $white;
              }
            }
          }
        }
        .sub-menu {
          padding-left: 20px;
          padding-top: 15px;
          li {
            a {
              padding-left: 15px;
              text-transform: none;
              &:after {
                content: '\f105';
                font-family: FontAwesome;
                display: block;
                width: 20px;
                height: 20px;
                border: 2px solid transparent;
                color: $white;
                transform: rotate(0deg);
                position: absolute;
                top: 0;
                left: 0;
              }
              &:hover {
                &:after {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
    aside.form-sidebar {
      @include form;
      padding: 25px;
    }
  }
}

.bg-gray{
  background-color: #f6f6f6;
}

//menu style

.menu-icon {
  a {
    color: $secondary;
    z-index: 999;
    position: relative;
    span {
      font-size: 40px;
      font-weight: 600;
    }
  }
}

.menu-btn {
  position: relative;
}

.menu {
  top: -100vh;
  left: 0;
  width: 50%;
  position: absolute;
  z-index: 999;
  margin: 0;
  padding: 0;
  visibility: hidden;
  transition: all .5s ease-in-out;
  &.open {
    top: 0;
    visibility: visible;
    transition: all .5s ease-in-out;
  }
  .close {
    font-size: 40px;
    color: $white;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 999;
    opacity: 1;
  }
}

.menu-header-2 {
  padding-top: 30px;
  margin-top: 30px;
  position: relative;
  &:after {
    content: '';
    display: block;
    border-top: 1px solid $white;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
  }
}

.contenitore-menu {
  width: 100%;
  background-color: $secondary;
  position: relative;
  z-index: 99;
  padding: 20px 30px;
  ul {
    li {
      margin-bottom: 20px;
      a {
        font-size: 17px;
        text-transform: uppercase;
        color: $white;
        font-weight: 600;
        padding-left: 30px;
        position: relative;
        border-bottom: 1px solid transparent;
        &:after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border: 2px solid $white;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          left: 0;
        }
        &:hover {
          color: $white;
          border-bottom: 1px solid transparent;
          &:after {
            background-color: $white;
          }
        }
      }
    }
    .sub-menu {
      padding-left: 20px;
      padding-top: 15px;
      li {
        a {
          padding-left: 15px;
          text-transform: none;
          &:after {
            content: '\f105';
            font-family: FontAwesome;
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid transparent;
            color: $white;
            transform: rotate(0deg);
            position: absolute;
            top: 0;
            left: 0;
          }
          &:hover {
            &:after {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.menu-icon {
  position: relative;
  cursor: pointer;
  i.fa, span {
    font-size: 30px;
    color: $secondary;
    display: inline-block;
    vertical-align: middle;
  }
  span {
    text-transform: uppercase;
    font-weight: 600;
  }
}


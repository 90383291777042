input[type=text], input[type=email], input[type=tel], input[type=password], input[type=number],  input[type=url], textarea, select, .jq-selectbox {
	padding: 10px;
	font-family: $font-stack;
	margin: 0 0 15px 0;
	border: 1px solid $white;
	border-radius: 5px;
	color: $white;
	height: 60px;
	width: 100%;
	transition: all $duration ease;
	font-size: 18px;
	font-style: normal;
	text-align: left;
	background-color: transparent;
	&:focus {

		text-align: left;
		&::-webkit-input-placeholder {
			color: transparent;
		}
		&::-moz-placeholder {
			color: transparent;
		}
		&:-ms-input-placeholder {
			color: transparent;
		}
		&:-moz-placeholder {
			color: transparent;
		}
	}
	//&:disabled {
	//	background-color: lighten($black, 85%) !important;
	//	color: lighten($black, 50%) !important;
	//	border-color: lighten($black, 85%) !important;
	//}
}

::-webkit-input-placeholder {
	color: $white;
	transition: all 150ms ease;
}

::-moz-placeholder {
	color: $white;
	transition: all 150ms ease;
}

:-ms-input-placeholder {
	color: $white;
	transition: all 150ms ease;
}

:-moz-placeholder {
	color: $white;
	transition: all 150ms ease;
}

.placeholder {
	color: $white;
	transition: all 150ms ease;
}


label {
	-webkit-touch-callout: none;
	user-select: none;
	color: inherit;
	font-weight: 400;
}

textarea {
	resize: none;
	width: 100%;
	max-width: 100%;
	display: block;
	height: 115px;
}

select {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	user-select: none;
	min-width: 0;
	width: 100%;
	display: block;
	text-align: left;
	option {
		color: $main;
		width: 100%;
	}
}

input[type=file]{
	display: inline-block;
	padding: 10px;
	font-family: $font-stack;
	margin: 0 0 15px 0;
	border: 1px solid $white;
	border-radius: 5px;
	color: $white;
	height: 60px;
	width: 100%;
	transition: all $duration ease;
	font-size: 18px;
	font-style: normal;
	text-align: left;
	background-color: transparent;
}



.icn {
  background-image: url(../images/spritesheet.png);
  background-repeat: no-repeat;
  display: block;
}

.icn-double-circle-24 {
  width: 88px;
  height: 88px;
  background-position: -5px -5px;
}

.icn-address {
  width: 29px;
  height: 26px;
  background-position: -103px -5px;
}

.icn-box {
  width: 41px;
  height: 39px;
  background-position: -142px -5px;
}

.icn-box-delivery {
  width: 69px;
  height: 69px;
  background-position: -193px -5px;
}

.icn-car {
  width: 48px;
  height: 36px;
  background-position: -272px -5px;
}

.icn-cell {
  width: 40px;
  height: 40px;
  background-position: -330px -5px;
}

.icn-dollar {
  width: 40px;
  height: 40px;
  background-position: -380px -5px;
}

.icn-envelope {
  width: 26px;
  height: 20px;
  background-position: -430px -5px;
}

.icn-double-circle-map {
  width: 88px;
  height: 88px;
  background-position: -466px -5px;
}

.icn-map-marker {
  width: 69px;
  height: 69px;
  background-position: -5px -103px;
}

.icn-query {
  width: 95px;
  height: 84px;
  background-position: -84px -103px;
}

.icn-double-circle-recycle {
  width: 88px;
  height: 88px;
  background-position: -189px -103px;
}

.icn-romb-check {
  width: 126px;
  height: 126px;
  background-position: -287px -103px;
}

.icn-romb-coin {
  width: 128px;
  height: 128px;
  background-position: -423px -103px;
}

.icn-romb-glass {
  width: 128px;
  height: 128px;
  background-position: -5px -241px;
}

.icn-romb-map-marker {
  width: 126px;
  height: 126px;
  background-position: -143px -241px;
}

.icn-romb-part {
  width: 126px;
  height: 126px;
  background-position: -279px -241px;
}

.icn-romb-procent {
  width: 128px;
  height: 128px;
  background-position: -415px -241px;
}

.icn-romb-stamp {
  width: 128px;
  height: 128px;
  background-position: -5px -379px;
}

.icn-romb-time {
  width: 126px;
  height: 126px;
  background-position: -143px -379px;
}

.icn-title-coin {
  width: 108px;
  height: 128px;
  background-position: -279px -379px;
}

.icn-title-document {
  width: 107px;
  height: 107px;
  background-position: -397px -379px;
}

.icn-title-flower {
  width: 108px;
  height: 108px;
  background-position: -564px -5px;
}

.icn-title-gorn {
  width: 108px;
  height: 83px;
  background-position: -561px -123px;
}

.icn-title-heart {
  width: 108px;
  height: 101px;
  background-position: -561px -216px;
}

.icn-title-info {
  width: 107px;
  height: 107px;
  background-position: -553px -327px;
}

.icn-title-part {
  width: 108px;
  height: 80px;
  background-position: -514px -444px;
}

.icn-title-recycle {
  width: 108px;
  height: 108px;
  background-position: -5px -534px;
}

.icn-title-user {
  width: 97px;
  height: 127px;
  background-position: -123px -534px;
}

.icn-user {
  width: 69px;
  height: 69px;
  background-position: -230px -534px;
}

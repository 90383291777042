@media (max-width: 1440px) {
  main {
    background-size: 9vw;
  }
  .deference-bg .triangle-deference .top:after{
    background-size: 11.5vw;
  }
  .deference-bg .triangle-deference .middle{
    background-size: 37vw;
  }
}

@media (max-width: 1250px) {
  .first {
    .h1-block {
      .wrap {
        &:after, &:before {
          width: 57%;
        }
      }
    }
    &-edge-list {
      padding: 40px 0 0;
      .text {
        width: 67%;
      }
    }
    h1 {
      margin-left: 0;
      span {
        font-size: 62px;
      }
      &:after {
        width: 64%;
      }
    }
    &-bottom {
      height: 250px;
    }
    .romb-filter {
      &:before {
        width: 68%;
        height: 50%;
        right: -5%;
      }
      &:after {
        width: 650px;
        background-size: 100% 100%;
        height: 650px;
        position: absolute;
        bottom: 41px;
        right: -116px;
      }
      &:before {
        width: 550px;
        height: 350px;
        right: -5%;
        background-size: 100%;
      }
    }
    &-list {
      width: 60%;
      margin: 0 auto;
      padding-left: 85px;
    }

  }

  .title {
    margin-left: 0;
    .wrap {
      &:after {
        width: 60%;
      }
    }
    span {
      font-size: 60px;
      padding-left: 110px;
      small {
        font-size: 50%;
      }
    }
    &:after, &:before {
      width: 60%;
    }
    .icn {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }
  }

  .header {
    .tagline {
      display: none;
    }
    .header-contact {
      display: none;
    }
    .header-phone {
      text-align: right;
    }
    .head-item {
      width: 33.33%;
    }
  }

  .reception {
    &-list {
      .item {
        .wrap {
          .text {
            height: 195px;
            .name {
              font-size: 18px;
            }
            p {
              font-size: 15px;
              line-height: 30px;
            }
            ul {
              li {
                a {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  .how {
    height: auto;
    .wrapper {
      align-items: flex-end;
      .how-2 {
        position: relative;
        .wrap {
          width: 100%;
          .tit {
            font-size: 28px;
          }
          .note {
            padding-bottom: 15px;
          }
        }
      }
    }
  }

  .why {
    background-position-y: 70%;
  }

  .action {
    &-name {
      font-size: 33px;
      span {
        font-size: 14px;
      }
    }
    &-form {
      .input-wrap {
        width: 100%;
        input:not([type=submit]) {
          width: 100%;
        }
      }
    }
  }

  .footer {
    .wrapper {
      flex-wrap: wrap;
      width: 70%;
      margin: 0 auto;
      .logo {
        order: 1;
      }
      .footer-social {
        order: 2;
      }
      .footer-menu {
        order: 3;
      }
      .footer-menu-2 {
        order: 4;
      }
      .item {
        width: 50%;
      }
    }
  }

}

@media (max-width: 1200px) {
  .first {
    height: auto;
    padding-bottom: 190px;
    h1 {
      display: block;
      margin: 0 auto;
      text-align: center;
      font-size: 40px;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      span {
        font-size: 50px;
      }
      &:after {
        border-left: none;
        width: 60%;
        left: 50%;
        transform: translateX(-50%);
      }

    }
    .subtitle {
      text-align: center;
    }
    .romb-filter {
      &:before, &:after {
        display: none;
      }
    }

    .h1-block {
      .wrap {
        &:after, &:before {
          display: none;
        }
      }
    }
    .btn-wrap {
      width: 400px;
      margin: 0 auto;
      display: block;
    }
    &-bottom {
      background: $section-bg;
      height: 190px;
      .wrapper {
        padding: 0;
      }
    }
    &-edge-list {
      flex-direction: row;
      padding: 30px 0;
      .item {
        width: 33.33%;
        margin-bottom: 30px;
        &:nth-child(2) {
          padding-top: 0;
        }
      }
    }
  }

  .title {
    text-align: center;
    padding: 30px 0;
    span {
      padding-left: 0;
      line-height: 1.25em;
      small {
        text-align: center;
        padding: 0;
      }
    }
    &:after, &:before {
      display: none;
    }
    .wrap:after {
      left: 50%;
      transform: translateX(-50%);
      border-left: none;
    }
    .icn {
      display: none;
    }
  }

  .reception {
    .title {
      span small {
        text-align: center;
        padding-right: 0;
      }
    }
    &-list {
      flex-wrap: wrap;
      justify-content: center;
      width: 80%;
      margin: 0 auto;

      .item {
        width: 50%;
        margin-bottom: 30px;
      }
    }
  }

  .process {
    height: auto;
    &-list {
      flex-wrap: wrap;
      justify-content: center;
      .item {
        width: 33.33%;
        margin-bottom: 30px;
      }
    }
  }

  section:not(.first) {
    padding: 50px 0 !important;
  }

  .how {
    .wrapper {
      flex-direction: column;
      .how-1, .how-2 {
        width: 100%;
      }
      .how-2 {
        text-align: center;
        .wrap {
          width: 50%;
          float: none;
          display: inline-block;
        }
      }
    }
  }

  .why {
    background: none;
    &-wrap {
      width: 100%;
    }
  }

  .consult {
    .wrapper {
      flex-direction: column;
      .consult-1, .consult-2 {
        width: 100%;
      }
      .consult-1 {
        .text {
          text-align: center;
        }
      }
      .consult-2 {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

    }
  }

  .action {
    .wrapper {
      flex-direction: column;
      padding: 0 15px;
      .action-name, .action-form {
        width: 100%;
      }
    }
    &-name {
      margin-bottom: 30px;
      line-height: 1.25em;
      span {
        display: block;
      }
    }
    &-form {
      .input-wrap {
        &:after {
          border-width: 3px;
        }
      }
    }
  }

  .footer .wrapper {
    width: 100%;
  }

  main .h1-block h1 {
    font-size: 46px;
  }

  .with-sidebar .sidebar aside.menu-sidebar ul li a {
    font-size: 15px;
  }

  .part-form .wrapper .area-group{
      width: 79.5%;
      textarea{
        width: 100%;
      }
    }

  .with-sidebar .sidebar aside.menu-sidebar{
    padding: 20px 15px;
  }

}

@media (max-width: 991px) {

  .first{
    padding-bottom: 365px;
    &-bottom{
      height: 365px;
    }
    &-edge-list{
      flex-direction: column;
       .item {
         width: 100%;
       }
    }
  }
  .reception-list {
    .item {
      width: 65%;
    }
  }

  .footer {
    .wrapper {
      padding: 0 15px;
    }
  }

  .with-sidebar {
    flex-direction: column;
    .main-block {
      width: 100%;
    }
    .sidebar{
      display: none;
    }
  }

  main .h1-block {
    width: 97%;
  }

  .vacancy {
    .we-offer {
      flex-direction: column;
      .img-wrap, .text {
        width: 100%;
        padding: 0;
      }
      .img-wrap {
        margin-bottom: 35px;
        text-align: center;
      }
    }
  }

  .contact-map .contact-block .wrap{
    margin: 0 auto;
  }

  .part-form {
    .wrapper{
      width: 100%;
      .input-group{
        width: 50%;
        padding: 0 15px;
        input:not([type=submit]), textarea{
          width: 100% ;
          margin-bottom: 15px;
        }
      }
      .area-group{
        width: 100%;
      }

    }
  }

  .list-gallery .item{
    width: 50%;
    text-align: center;
    margin-bottom: 30px;
  }

  .category-katalizator-list{
    flex-direction: column;
    align-items: center;
    .item{
      width: 50%;
      margin-bottom: 30px;
    }
  }

  .deference-bg .triangle-deference .middle {
    background: none;
    .text{
      width: 100%;
    }
  }

  .contact-map{
    height:auto;
    display: flex;
    flex-direction: column;
    .map{
      position: relative;
      order: 2;
    }
    .cont{
      order: 1;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 767px) {
  .container {
    width: 450px;
  }

  .menu {
    width: 100%;
  }

  .header {
    .wrapper {
      flex-wrap: wrap;
      height: 150px;
      padding-top: 0;
      .menu-btn {
        width: 100%;
        text-align: center;
        background-color: $secondary;
        .menu-icon {
          span, i.fa {
            color: $white;
          }
        }
      }
      .logo {
        width: 50%;
      }
      .header-phone {
        width: 50%;
      }
    }
  }

  .first {
    h1 {
      font-size: 30px;
      span {
        font-size: 40px;
      }
    }

    &-list {
      padding-left: 0;
      width: 100%;
      .item {
        width: 74%;
        br {
          display: none;
        }
      }
    }
    &-bottom {
      height: 395px;
    }
  }

  .reception-list .item {
    width: 100%;
  }

  .how .wrapper .how-2 .wrap {
    width: 100%;
  }

  .why-list .text {
    width: 67%;
  }

  .why-wrap .about-link {
    font-size: 17px;
  }

  .reviews-list {
    columns: 1;
  }

  .consult .wrapper .consult-2 {
    flex-direction: column;
  }

  .action {
    height: auto;
    &-form {

      .input-wrap {
        height: auto;
        input:not([type=submit]) {
          padding-right: 0;
        }
        .btn-wrap {
          position: relative;
          right: 0;
          top: 0;
          transform: translateY(0%);
          width: 100%;
          padding-top: 15px;
          input[type=submit] {
            width: 100%;
          }
        }
        &:after {
          width: 100%;
          border-right: 3px solid $white;
        }
      }
    }
  }

  .footer {
    .wrapper {
      .item {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .blog-list .item {
    width: 100%;
  }

  .article__bottom {
    flex-direction: column;
    .main-block {
      margin-bottom: 45px;
    }
    .left {
      margin-bottom: 35px;
    }
  }

  .page .slogan {
    width: 100%;
  }

  .contact-map .contact-block .wrap{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 35px;
  }

  .about-text{
    width: 100%;
  }

  .serteficate-list .item {
    width: 50%;
  }

  .first-bottom__about{
    height: auto;
  }

  .partners-text{
    width: 100%;
  }

  .part-form .wrapper .input-group{
    width: 100%;
  }

  .category-katalizator-list .item{
    width: 100%;
  }

  .blog-list .item a .name{
    font-size: 18px;
    margin-bottom: 15px;
  }

  .blog-list .item a p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 303px;
  }

  .header {
    .wrapper {
      height: 220px;
      justify-content: center;
      .logo {
        width: 100%;
      }
      .header-phone {
        width: 100%;
        text-align: center;
        .phone {
          text-align: center;
        }
      }
    }
  }

  .first {
    padding-top: 220px;
    .wrapper {
      padding: 30px 9px 50px;
    }
    h1 {
      span {
        font-size: 34px;
      }
    }
    .subtitle {
      font-size: 26px;
      line-height: 40px;
      span {
        font-size: 24px;
        line-height: 40px;
      }
    }
    &-bottom {
      .wrapper {
        padding: 0;
      }
    }
    &-edge-list {
      .text {
        font-size: 14px;
        span {
          font-size: 36px;
        }
      }
    }
  }

  .title {
    span {
      font-size: 30px;
    }
  }

  .reception {
    &-list {
      width: 100%;
      .item {
        .wrap {
          .text {
            padding: 30px 10px;
          }
        }
      }
    }
  }

  .process {
    .wrapper {
      padding: 0 15px;
    }
    &-list {
      .item {
        width: 100%;
      }
    }
  }

  .how {
    &-list {
      text-align: center;
      .text {
        width: 100%;
        padding-left: 0;
        align-items: center;
      }
    }
    .wrapper {
      .how-2 {
        .wrap {
          padding: 15px 0;
        }
      }
    }
  }

  .why {
    &-list {
      .item {
        text-align: center;
      }
      .text {
        width: 100%;
        padding-left: 0;
      }
    }
    &-wrap {
      .about-link {
        margin-left: 0;
      }
    }
  }

  .contenitore-menu {
    padding: 20px 20px;
    ul li a {
      font-size: 14px;
    }
  }

  .footer-menu ul li a {
    font-size: 15px;
  }

  main {
    .wrapper {
      background: none;
    }
    .h1-block {
      h1 {
        font-size: 48px;
      }
    }
    .h1-block h1 {
      font-size: 30px;
    }
  }

  .with-sidebar .sidebar aside.form-sidebar {
    padding: 15px 10px;
  }

  .with-sidebar .sidebar aside .sidebar-title {
    font-size: 22px;
  }

  .page .slogan {
    font-size: 14px;
    line-height: 1.35em;
  }

  .content {
    p, h2, h3, h4, li {
      line-height: 1.35em;
      margin-top: 0;
    }
    p, h2, h3 {
      margin-bottom: 25px;
    }
    p {
      font-size: 14px;
    }
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 14px;
    }
    ul {
      padding-left: 25px;
    }
  }

  .vacancy{
    &-list {
      .item {
        .info-list{
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 10px;
        }
        .text{
          font-size: 14px;
          line-height: 1.35px;
        }
        .name{
          margin-bottom: 25px;
          font-size: 22px;
        }
        .salary{
          span{
            display: inline-block;
          }
        }
        .requirements-list{
          li{
            font-size: 14px;
            line-height: 1.35em;
          }
        }
      }
    }
  }

  .md{
    padding: 30px 10px;
    .title-md{
      font-size: 24px;
    }
    p{
      font-size: 16px;
    }
  }

  .contact-map .contact-block .phone-block {
    font-size: 28px;
  }

  .contact-map .contact-block a:not(.btn)[href*=mailto] {
    font-size: 18px;
  }

  .serteficate-list .item{
    width: 100%;
  }

  .first-edge-list .text {
    width: 65%;
  }

  .list-gallery .item{
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .btn.btn-big, input[type='submit'].btn-big, button[type='submit'].btn-big{
    font-size: 17px;
  }
  #scrollup{
    img{
      width: 75%;
      float: right;
    }
  }
  .consult .wrapper {
    .consult-1 .text{
      p{
        font-size: 16px;
      }
    }
    .consult-2{
      display: none;
    }
  }
  .how .wrapper .how-2 .wrap .tit {
    font-size: 22px;
  }
}
